import Glide from '@glidejs/glide';

export const ArrowDisabler = function (Glide, Components, Events) {
  return {
    mount() {
      Events.on(['mount.after', 'run', 'resize'], () => {
        const { perView } = Glide._o;
        const { slides } = Components.Html;
        const shouldHideArrows = slides.length < perView;

        if (shouldHideArrows) {
          const { items } = Components.Controls;
          items.forEach(item => {
            const arrowPrev = item.querySelector('[data-glide-dir="<"]');
            const arrowNext = item.querySelector('[data-glide-dir=">"]');

            arrowPrev && arrowPrev.classList.add('glide__arrow--disabled');
            arrowNext && arrowNext.classList.add('glide__arrow--disabled');
          });
        }
      });
    }
  };
};

export const makeCarousel = (carouselEl, options) => new Glide(carouselEl, options).mount({ ArrowDisabler });
export const makeUnmountedCarousel = (carouselEl, options) => new Glide(carouselEl, options);
