import { h } from 'preact';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react';
import WhatsAppFormWithModal from '../../auto/components/Forms/Whatsapp/PerformanceWhatsappForm/FormApp';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

(() => {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) =>
    render(vnode, parent, parent.firstElementChild);

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    WhatsAppFormWithModal,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
